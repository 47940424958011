
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Box from "./modules/box.vue";
import Sixbox from "./modules/SixBoxa.vue";
import FourBoxa from "./modules/FourBoxa.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Box,
    Sixbox,
    FourBoxa,
  },
  data() {
    return {
      themeImg: require("@/assets/1new/生涯研学/2.jpg"),
      featureImg: require("@/assets/1new/生涯研学/3.jpg"),
      resultImg: require("@/assets/1new/生涯研学/4.jpg"),
      recentlyImg: require("@/assets/1new/生涯研学/近期活动.jpg"),
      previousImg: require("@/assets/1new/生涯研学/往期回顾.jpg"),
      cityList:[
        require("@/assets/1new/生涯研学/5.jpg"),require("@/assets/1new/生涯研学/6.jpg"),require("@/assets/1new/生涯研学/7.jpg"),
      ],
        platfromDetail: [
        require("@/assets/1new/生涯云平台/轮播图片/1.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/2.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/3.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/4.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/5.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/6.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/7.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/8.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/9.jpg"),
      ],
    };
  },
});
